import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import './index.less';


interface IProps {
    imgs?: string[],
    img: string,
    onClose: () => void
}

interface IState {
    current: string,
    currentIndex: number
}
export default class PreviewImg extends Component<IProps, IState> {
    container: HTMLDivElement;

    constructor(props: IProps) {
        super(props)

        this.state = {
            current: '',
            currentIndex: 0
        }


        this.container = document.createElement('div')

        document.body.appendChild(this.container)
    }

    componentDidMount() {


        document.addEventListener('keydown', (e: any) => {
            if (e.key === "Escape") {
                this.props.onClose()
            }
        })



        const { imgs, img } = this.props

        this.setState({
            current: img,
            currentIndex: imgs?.indexOf(img)!
        })

    }

    componentWillReceiveProps(props: IProps) {
        console.log(props)
    }

    componentWillUnmount() {
        document.body.removeChild(this.container)

        document.removeEventListener('keydown', () => {

        })
    }

    preImg = (e: any) => {
        e.stopPropagation()

        const { imgs } = this.props;
        let currentIndex = imgs!.indexOf(this.state.current)


        if (currentIndex === 0) {
            return
        }
        this.setState({
            current: imgs![currentIndex - 1],
            currentIndex: currentIndex - 1
        })
    }

    nextImg = (e: any) => {
        e.stopPropagation()

        const { imgs } = this.props;
        let currentIndex = imgs!.indexOf(this.state.current)
        if (currentIndex === imgs?.length! - 1) return
        this.setState({
            current: imgs![currentIndex + 1],
            currentIndex: currentIndex + 1
        })
    }


    render() {
        const { onClose, imgs } = this.props;
        const { current, currentIndex } = this.state;
        return ReactDOM.createPortal(

            <div className="previewImg" onClick={onClose}>

                <div className="close iconfont icon-close-bold" onClick={onClose}>

                </div>

                <img src={current} alt="" className="imgMain" onClick={(e) => e.stopPropagation()} style={{ height: "80%", objectFit: "contain" }} />

                {
                    currentIndex !== 0 ?
                        <i className="iconfont icon-arrow-left-filling" onClick={this.preImg}></i>
                        :
                        null
                }

                {
                    currentIndex !== imgs?.length! - 1 ?
                        <i className="iconfont icon-arrow-right-filling" onClick={this.nextImg}></i>
                        :
                        null
                }

            </div>
            , this.container)
    }
}
