/* eslint-disable react/style-prop-object */
import React from 'react'
import './index.less'

//@ts-ignore
const { CanvasParticle } = window;

// import 
function Sidebar() {

    const [isPlay, setPlay] = React.useState(false)
    React.useEffect(() => {

        // 配置
        var config = {
            vx: 4,	//小球x轴速度,正为右，负为左
            vy: 4,	//小球y轴速度
            height: 2,	//小球高宽，其实为正方形，所以不宜太大
            width: 2,
            count: 200,		//点个数
            color: "121, 162, 185", 	//点颜色
            stroke: "130,255,255", 		//线条颜色
            dist: 6000, 	//点吸附距离
            e_dist: 20000, 	//鼠标吸附加速距离
            max_conn: 10 	//点到点最大连接数
        }

        CanvasParticle(config)

        // createCanvasParticle()

    }, [])

    let audioRef: HTMLAudioElement | null;
    const clickLogo = async (e: any) => {

        e.stopPropagation()

        if (audioRef !== null) {
            if (audioRef.paused) {
                audioRef.play();// 播放

                setPlay(true)
            } else {
                audioRef.pause();// 暂停
                setPlay(false)
            }
        }


        //    React.createElement('img',{
        //        src:"",
        //        alt:""
        //    })

        // React.createElement('div', null, React.createElement('a', {
        //     href: "",

        // },'Click'))


    }


    return (
        <div className="sidebar" id="mydiv-bg">
            {/* <canvas style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%" }}></canvas> */}
            <div className="title-logo">
                <img src={require('../../assets/imgs/logo.JPG')} className={isPlay ? 'App-logo' : ''} alt="" onClick={clickLogo} />

                <h3 className="title">
                    <a href="/">
                        WBS
                        </a>
                </h3>
                <div className="desc">
                    <p>只想生活，不想谋生。</p>
                </div>

                <ul className="social-links">
                    <li>
                        <a href="https://github.com/wu0211" title="github" rel="noopener noreferrer" target="_blank" className="iconfont">&#xe677;</a>
                    </li>
                </ul>
            </div>

            <audio loop style={{ opacity: 0 }} ref={node => audioRef = node}>
                {/* <source src={require('../../assets/audio/sky_city.mp3')} /> */}
                <source src="https://wu-blog.oss-cn-beijing.aliyuncs.com/sky_city.mp3" />

                {/* 您的浏览器不支持 audio 元素。 */}
            </audio>

            <footer>

                <a href="http://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer" style={{ fontSize: "14px" }}>京ICP备2020039432号</a>

                <div style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
                    <img src={require('../../assets/imgs/beian.png')} alt="" className="beianLogo" />
                    <a target="_blank" rel="noopener noreferrer" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011202002468" ><p >京公网安备 11011202002468号</p></a>
                </div>



            </footer>
        </div>
    )
}

export default React.memo(Sidebar)