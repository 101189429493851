import React, { useEffect } from 'react'
import './index.less';

import { Link, useHistory } from 'react-router-dom';
const navList = [{
    title: "主页",
    router: "/"
}
    , {
    title: "归档",
    router: "/projects"
}, {
    title: "关于",
    router: "/about"
}, {
    title: "技术链接",
    router: "/links"
}
]

const baseTitle = "吴伯松的小屋";

export default function Nav() {

    let history = useHistory()

    let [curRouter, setCurRouter] = React.useState('/')


    useEffect(() => {

        history.listen(listen_router => {



            document.title = getTitle(listen_router.pathname)

        })


        setCurRouter(history.location.pathname)

        return () => {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location.pathname])



    const getTitle = (pathname: string) => {




        return `${getFirstTitle(pathname)}  ${baseTitle}`


    }


    const getFirstTitle = (pathname: string) => {

        if (pathname === "/") return ""


        let nav = navList.find(item => item.router === pathname)

        return nav?.title ? nav.title + " · " : ""

    }

    return (
        <div className="nav">
            {
                navList.map((item) => {
                    return (
                        <div className={`item `} key={item.title} >
                            <Link to={item.router} className={`${item.router === curRouter ? 'current' : ''}`} onClick={() => setCurRouter(item.router)}>
                                {item.title}
                            </Link>
                        </div>
                    )
                })
            }
        </div>
    )
}
