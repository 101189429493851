import React from 'react'
import { Link, useHistory } from 'react-router-dom';
import { isEnvProduction } from '../../../utils/utils';

import './index.less';


function Project(props: IProject) {

    const history = useHistory()

    const edit = () => {



        if (isEnvProduction) return

        history.push('/create/' + props.id)
    }

    const gotoDetail = (e: any) => {





        e.stopPropagation();
        // history.push(props.router)
    }

    return (
        <div className="project" onClick={edit} style={{ animation: 'show ' + ((props.index! + 1) * 0.2 + 1) + 's 1' }}>
            <div >
                <h3 className="pr" onClick={gotoDetail} >
                    <Link to={props.router} >
                        {props.title}
                    </Link>


                    {/* <div className="shareImg">
                        <img src="https://wu-blog.oss-cn-beijing.aliyuncs.com/projects/tjyh_share.JPG" alt="" />
                    </div> */}
                </h3>


            </div>
            <div className="project-content" >
                <p>
                    {props.content}
                </p>
            </div>
            <div className="project-footer">
                <i ></i>
                <span className="date">{props.date}</span>
                <i className="iconfont icon-tag"></i>
                <span className="tag">{props.type}</span>

            </div>
        </div>
    )
}


export default React.memo(Project)