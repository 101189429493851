import React from 'react'
import Project from './item'

import './index.less';
import { getProjects } from '../../utils/utils';




export default function Projects() {

    let [projects, setProjects] = React.useState<IProject[]>([])


    React.useEffect(() => {


        setProjects(getProjects())

    }, [])

    return (
        <div className="projects">
            {
                projects.map((item, index) => {

                    return (
                        <Project {...item} key={index} index={index} />
                    )
                })
            }
        </div>
    )
}
