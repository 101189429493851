import React from 'react'

import { HashRouter as Router, Route, MemoryRouter } from 'react-router-dom'

export default function CustomBrowserRouter({ children }: any) {
    return (


        <MemoryRouter >
            <Router>
                <Route >
                    {
                        children
                    }
                </Route>
            </Router>
        </MemoryRouter>
    )
}
