import React from 'react'
// import Highlight from 'react-highlight'
import './index.less'
// import "highlight.js/scss/atom-one-dark-reasonable.scss";

// import 'highlight.js/styles/dark.css';


const FamiliarTechnology: string[] = ['微信小程序', 'javascript', 'typescript', 'vue', 'uni-app', 'h5']
const StudyTechnology: string[] = ['react', 'nodejs', 'nestjs', 'graphql', 'python', 'seo']

export default function About() {


    // let [wind, setWin] = React.useState<Window | null>(null)

    //     let array= [1, 1, 2, 3, 5, 5, 1]
    //    array.filter((arr, index) => array.indexOf(arr)===index)

    // const openNewWindw = () => {

    //     // window.
    //     wind = window.open('https://www.baidu.com', 'newWindow', 'height=100, width=400, top=0, left=0, toolbar=no, menubar=no, scrollbars=no,resizable=no,location=no, status=no')
    //     wind?.focus()
    // }

    return (
        <div className="about">

            <h2>很高兴认识你</h2>


            {/* <p>少吃火锅多吃苦</p> */}
            {/* <p>人生没有对错，只有选择</p> */}

            <p>我叫吴伯松，96年生，天津人</p>

            <p>毕业于天津电子信息职业技术学院 &nbsp;&nbsp; 软件技术专业</p>

            {/* <p>喜欢编程，做一些小程序，爱听歌、跑步</p> */}

            <p className="mt30">2017年6月开始工作</p>
            <p>在公司负责网站、后台管理、小程序等开发</p>

            <h4>熟悉的一些技术</h4>

            <ul>
                {
                    FamiliarTechnology.map((item) => {
                        return (
                            <li key={item}>
                                <code>{item}</code>
                            </li>
                        )
                    })
                }
            </ul>
            <h4>学过的一些技术</h4>

            <ul>
                {
                    StudyTechnology.map((item) => {
                        return (
                            <li key={item}>
                                <code>{item}</code>
                            </li>
                        )
                    })
                }
            </ul>


            <h4>联系方式</h4>
            <ul>
                <li>
                    邮箱：<code>wbs_211@163.com</code>
                </li>
                <li className="wechat">
                    微信：<code >wbs_1996</code>

                    <img className="wechat_img" src="https://wu-public.oss-cn-beijing.aliyuncs.com/wechat_code.JPG" style={{ width: "200px" }} alt="" />
                </li>
            </ul>


            {/* <div className="list">
                <div className="item"></div>
                <div className="item"></div>
                <div className="item"></div>
                <div className="item"></div>
                <div className="item"></div>

            </div> */}




            {/* <pre>

                function name(params:type) {

                }

            </pre>
            <code> let a:number=123</code>
            <div dangerouslySetInnerHTML={{ __html: "<div>121113</div>" }}></div>


            <Highlight >
                function name(params:type) {

                }
            </Highlight>


            <Highlight>Hello React</Highlight>



            <Highlight >
                {"html with multiple code snippets"}
            </Highlight> */}

            {/* <button onClick={openNewWindw}>yidong</button>

            <button onClick={() => wind?.close()}>close</button> */}

        </div>
    )
}
