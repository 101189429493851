import React from 'react'

export default function NotFount() {
    return (
        <div className="notFount">

            <h4>正在努力编写中~</h4>
        </div>
    )
}
