import { Projects } from './constants';

export const isEnvProduction = process.env.NODE_ENV === 'production';

export const getProjects = () => Projects;

export const getProjectById = (id: string | number) => Projects.find(item => item.id === id);






abstract class Animals {
    name: string
    constructor(name: string) {
        this.name = name
    }

    abstract eat(): void

    run() {

        console.log(this.name + 'run')
    }
}

class Monck extends Animals {
    eat(): void {


    }

}

let monck = new Monck('monck')
monck.run()
