import React from 'react';
// import logo from './logo.svg';
import './App.css';

import CustomBrowserRouter from './containers/custom-browser-router';
import Home from './views/home';
function App() {

  // const datafun = (a: number) => (b: number) => {

  //   console.log(a)
  //   console.log(b)
  //   return a + b;

  // }

  // console.log('start a')
  // let a = datafun(1)

  // console.log(a)

  // console.log('start b')
  // let b = a(2)

  // console.log(b)

  // console.log('start c')
  // let c = a(5)

  // console.log(c)
  return (
    <CustomBrowserRouter>
      <Home />
    </CustomBrowserRouter>


  );
}



export default App;



// class Test extends Component {

//   constructor(props: any) {
//     super(props)

//     this.state = {

//     }

//     this.testClick = this.testClick.bind(this)
//   }


//   testClick() {

//   }

//   render() {
//     return (
//       <div onClick={this.testClick}>

//       </div>
//     )
//   }
// }



