import React from 'react'
import { Route, Switch, useHistory } from 'react-router'
import Nav from '../nav'
import './index.less'
import About from '../about/index';
import Projects from '../project';
import Links from '../links';
import Main from '../main';
import Sidebar from '../sidebar';
import CreateProject from '../create-project';
import NotFount from '../notFound';

import ProjectDetail from '../project/detail/index';

export default function Home() {


    let history = useHistory()

    let [isShowTop, setShowTop] = React.useState(false)

    React.useEffect(() => {


        let height = window.screen.availHeight / 2;

        document.addEventListener('scroll', (_) => {
            // console.log(e)

            const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

            if (scrollTop > height && !isShowTop) {

                setShowTop(true)

            } else {
                setShowTop(false)

            }
        })

        history.listen(() => {
            window.scrollTo(0, 0)
        })



        return () => {
            document.removeEventListener('scroll', _e => { })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])




    return (
        <div className="home">
            <div className="left">
                <Sidebar />
            </div>
            <div className="right">

                <Nav />

                <div className="content">


                    <div className="page">
                        <Switch>

                            <Route component={Main} path="/" exact />
                            <Route component={Projects} path="/projects" exact />
                            <Route component={About} path="/about" exact />
                            <Route component={Links} path="/links" exact />

                            <Route component={ProjectDetail} path="/project/:id" exact />

                            <Route component={CreateProject} path={["/create/:id", "/create"]} />

                            <Route component={NotFount} />
                        </Switch>
                    </div>

                </div>

                {
                    isShowTop ?
                        <div className="up" onClick={() => window.scrollTo(0, 0)}>
                            <i className="iconfont icon-top-filling"></i>
                            <div className="upHover">
                                <i className="iconfont icon-top-filling"></i>
                            </div>
                        </div>
                        : null
                }


            </div>
        </div>
    )
}
