import React, { Component } from 'react'
import { RouteChildrenProps } from 'react-router-dom';
import { getProjectById } from '../../../utils/utils';
import './index.less';
import PreviewImg from '../../previewImg/index';

// const ProjectDetail: React.FC = () => {


//     let [html, setHtml] = React.useState<string>("")

//     let { id } = useParams<{ id: string }>()

//     React.useEffect(() => {

//         let project = getProjectById(id)

//         project && setHtml(project.html || "")

//         return () => {

//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [])


//     React.useEffect(() => {

//         // document.getElementsByClassName('project-detail')[0].cli


//         document.querySelectorAll('img')?.forEach(item => {

//             item.addEventListener('click', (e: any) => {
//                 console.log(e.target.src)


//             })
//         })




//     }, [html])

//     const close = () => {

//     }


//     return (
//         <div className="project-detail" dangerouslySetInnerHTML={{ __html: html }}>


//             <PreviewImg img='' onClose={close} />
//         </div>
//     )
// }



interface IProps extends RouteChildrenProps<{ id: string }> {

}

interface IState {
    html: string,
    showPreview: boolean,
    currentImg: string,
    imgs: string[]
}

export default class ProjectDetail extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)

        this.state = {
            html: "",
            showPreview: false,
            currentImg: "",
            imgs: []
        }
    }


    componentDidMount() {


        let project = getProjectById(this.props.match?.params.id || "")




        let content = project!.html!;
        let imgReg = /<img.*?(?:>|\/>)/gi //匹配图片中的img标签
        // eslint-disable-next-line no-useless-escape
        let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i // 匹配图片中的src
        let arr = content.match(imgReg)  //筛选出所有的img

        let srcArr = []

        if (arr && arr.length > 0) {
            for (let i = 0; i < arr!.length; i++) {
                let src = arr![i].match(srcReg)
                // 获取图片地址
                let url = src![1];
                srcArr.push(this.formatterUrl(url))
            }
        }




        project && this.setState({
            html: project.html || "",
            imgs: srcArr
        }, () => {

            document.querySelector('.project-detail')?.addEventListener('click', (e: any) => {


                let url: string = e.target.src;

                if (!url) return


                url = this.formatterUrl(url)

                this.setState({
                    showPreview: true,
                    currentImg: url
                })
            })


        })



    }

    formatterUrl(url: string) {

        return url.indexOf('?') > -1 ? url.substring(0, url.indexOf('?')) : url
    }

    componentWillUnmount() {
        document.querySelector('.project-detail')?.removeEventListener('click', () => {

        })
    }


    close = () => {

        this.setState({
            showPreview: false
        })
    }


    render() {

        const { html, showPreview, currentImg, imgs } = this.state;
        return (
            <>
                <div className="project-detail" dangerouslySetInnerHTML={{ __html: html }}>



                </div>
                {
                    showPreview && <PreviewImg imgs={imgs} img={currentImg} onClose={this.close} />
                }
            </>
        )
    }
}
