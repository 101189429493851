import React from 'react'
import './index.less';
import Sortable from 'sortablejs';

let skyList: string[] = [

    // 'https://wu-blog.oss-cn-beijing.aliyuncs.com/sky/IMG_0371.HEIC?x-oss-process=style/thumbnail',
    'https://wu-blog.oss-cn-beijing.aliyuncs.com/sky/IMG_1066.HEIC?x-oss-process=style/thumbnail',
    'https://wu-blog.oss-cn-beijing.aliyuncs.com/sky/IMG_1245.HEIC?x-oss-process=style/thumbnail',
    'https://wu-blog.oss-cn-beijing.aliyuncs.com/sky/IMG_4674.HEIC?x-oss-process=style/thumbnail',
    'https://wu-blog.oss-cn-beijing.aliyuncs.com/sky/IMG_1247.HEIC?x-oss-process=style/thumbnail',
    'https://wu-blog.oss-cn-beijing.aliyuncs.com/sky/IMG_1939.HEIC?x-oss-process=style/thumbnail',
    'https://wu-blog.oss-cn-beijing.aliyuncs.com/sky/p1.jpg?x-oss-process=style/thumbnail',
    'https://wu-blog.oss-cn-beijing.aliyuncs.com/sky/p2.jpg?x-oss-process=style/thumbnail',
    'https://wu-blog.oss-cn-beijing.aliyuncs.com/sky/p4.jpg?x-oss-process=style/thumbnail',
    'https://wu-blog.oss-cn-beijing.aliyuncs.com/sky/p3.jpg?x-oss-process=style/thumbnail',
    'https://wu-blog.oss-cn-beijing.aliyuncs.com/sky/IMG_0086.JPG?x-oss-process=style/thumbnail',
    'https://wu-blog.oss-cn-beijing.aliyuncs.com/sky/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20180909123608.jpg?x-oss-process=style/thumbnail',
    'https://wu-blog.oss-cn-beijing.aliyuncs.com/sky/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20180909123624.jpg?x-oss-process=style/thumbnail',
    'https://wu-blog.oss-cn-beijing.aliyuncs.com/sky/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20180909123743.jpg?x-oss-process=style/thumbnail'
]
export default function Main() {


    React.useEffect(() => {

        let el = document.getElementById('imgs')
        // console.log(el)
        Sortable.create(el!, {
            animation: 150,
            onEnd: (event) => {
                console.log(event)
            }
        })
    }, [])

    return (
        <div className="main" id='imgs'>


            {/* <h1>Welcome</h1> */}


            {/* <div dangerouslySetInnerHTML={{ __html: `<h1>大家好</h1>` }}></div> */}


            {
                skyList.map((item, index) => {

                    return (

                        // <img src={item} key={index} alt="" style={{ animation: 'show ' + ((index! + 1) * 0.2 + 1) + 's' }} />
                        <img src={item} key={index} alt="" />

                    )
                })
            }


        </div>
    )
}
