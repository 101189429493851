import React from 'react'
import './index.less';


const list = [{
    title: "微信小程序",
    desc: "基于微信简单、高效的应用开发",
    link: "https://developers.weixin.qq.com/miniprogram/dev/framework/"
}, {
    title: "React",
    desc: "用于构建用户界面的 JavaScript 库",
    link: "https://react.docschina.org/"
}, {
    title: "Vue",
    desc: "渐进式JavaScript 框架",
    link: "https://cn.vuejs.org/"
}, {
    title: "Webpack",
    desc: "一个现代 JavaScript 应用程序的静态模块打包器",
    link: "https://www.webpackjs.com/"
}, {
    title: "NodeJs",
    desc: "一个基于 Chrome V8 引擎的 JavaScript 运行环境",
    link: "http://nodejs.cn/api/"
}, {
    title: "NestJs",
    desc: "用于构建高效且可伸缩的服务端应用程序的渐进式 Node.js 框架",
    link: "https://docs.nestjs.cn/"
}, {
    title: "Graphql",
    desc: "一种用于 API 的查询语言",
    link: "https://graphql.cn/"
}]

export default function Links() {
    return (
        <div className="links">
            {
                list.map((item, index) => {
                    return (
                        <div className="item" key={index} style={{ animation: 'show ' + ((index! + 1) * 0.2 + 1) + 's' }} >
                            <a href={item.link} target="_blank" title={item.desc} rel="noopener noreferrer">
                                <h4>{item.title}</h4>
                                <div className="desc">{item.desc}</div>
                            </a>

                        </div>
                    )
                })
            }

            {/* <div className="header">
                123123
            </div>
            <div className="header bg">
                123123
            </div>
            <div className="header">
                12312312321313
            </div> */}
        </div>
    )
}

