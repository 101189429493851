import React from 'react'
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import { useParams } from 'react-router-dom'
import { getProjectById } from '../../utils/utils';
export default function CreateProject() {

    let [isLivinig, setLiving] = React.useState(true)

    let [editorState, setEditorState] = React.useState(BraftEditor.createEditorState(''))

    let [outputHTML, setOutputHTML] = React.useState()

    let { id } = useParams<{ id: string }>()

    React.useEffect(() => {

        setLiving(true)
        // 3秒后更改编辑器内容
        setTimeout(setEditorContentAsync, 3000)

        return () => {
            setLiving(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleChange = (editorState: any) => {

        setEditorState(editorState)
        setOutputHTML(editorState.toHTML())
    }


    const setEditorContentAsync = () => {


        if (id) {

            let project = getProjectById(id)

            isLivinig && setEditorState(BraftEditor.createEditorState(project!.html || ""))

        }


    }
    return (
        <div>
            <div className="editor-wrapper">
                <BraftEditor
                    value={editorState}
                    onChange={handleChange}
                />
            </div>
            <h5>输出内容</h5>
            <div className="output-content">{outputHTML}</div>
        </div>
    )
}
